import { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";

import FormControl from "@busbud/design-system-components/dist/FormControl";
import DSCLTextField from "@busbud/design-system-components/dist/TextField";

import { FormInputProps } from "@components/forms/formInputsLayout";

export const TextField: FC<FormInputProps> = ({
  formItem: { name, label, placeholder, isRequired = false },
}) => {
  const { control, formState } = useFormContext();
  const errorMessage = formState.errors[name];

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { ref, ...field } }) => (
        <FormControl fullWidth>
          <DSCLTextField
            label={label}
            variant="filled"
            placeholder={placeholder}
            error={Boolean(errorMessage)}
            helperText={errorMessage?.message}
            inputRef={ref}
            {...field}
          />
        </FormControl>
      )}
    />
  );
};
