import { FC } from "react";

export const SVGCurve: FC = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 40"
    preserveAspectRatio="none"
  >
    <path
      d="M1200 33.244V40H0l50-5.63h.002C100.002 28.74 200 17.48 300 10.723 400 3.967 500 1.714 600 .588 700-.538 800-.538 900 5.093c99.999 5.63 200 16.89 250 22.52l50 5.63Z"
      fill="currentColor"
    />
  </svg>
);
