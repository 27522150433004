import { FC, useEffect, useState } from "react";

import { useRouter } from "next/router";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Grid } from "@busbud/design-system-components";

import { FormModal } from "@components/FormModal";
import { FormProps } from "@components/forms/formController";
import {
  FormInputsLayout,
  InputDefaultValue,
} from "@components/forms/formInputsLayout";
import { SendButton } from "@components/forms/inputs/sendButton";
import { useStyles } from "@components/forms/inputs/styles";
import { tracking } from "@services/tracking";
import { buildTicketMessage, createZendeskTicket } from "@utils/ticketMessage";

export const GenericZendeskForm: FC<FormProps> = ({
  handleClose,
  isOpen,
  form,
  formLabels,
}) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [hasSendSuccess, setHasSendSuccess] = useState<boolean>(false);
  const [hasSendError, setHasSendError] = useState<boolean>(false);
  const { formItem } = useStyles();
  const { locale = "" } = useRouter();
  useEffect(() => {
    if (isOpen) {
      tracking.openModal(form.formComponent, locale);
    }
  }, [isOpen, form.formComponent, locale]);

  const defaultValues = form.formItems?.reduce((acc, item) => {
    if (!item) return acc;
    return {
      ...acc,
      [item.name]: InputDefaultValue[item.inputComponent],
    };
  }, {});

  const formMethods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const onError = () => {
    tracking.formClickSubmit(form.formComponent, false, locale);
  };

  const onSubmit: SubmitHandler<Record<string, string>> = async (
    data,
    event
  ) => {
    tracking.formClickSubmit(form.formComponent, true, locale);
    event?.preventDefault();

    const ticket = await buildTicketMessage({
      requestType: form.requestType || "other",
      email: data.email,
      name: data.name,
      comment: data.comment,
      subject: form.emailSubject,
      formFields: {
        ...data,
      },
    });

    setIsSending(true);
    if (
      window.grecaptcha &&
      process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_CLIENT_KEY
    ) {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(
            process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_CLIENT_KEY as string,
            {
              action: `submit/form/${form.requestType}`,
            }
          );
          const response = await createZendeskTicket(ticket, token);
          if (response.ok) {
            tracking.formSubmitSuccess(form.formComponent, locale);
            setHasSendSuccess(true);
            setIsSending(false);
          } else {
            tracking.formSubmitError(form.formComponent, locale);
            setHasSendError(true);
            setIsSending(false);
          }
        } catch (error) {
          tracking.formSubmitError(form.formComponent, locale);
          setHasSendError(true);
          setIsSending(false);
        }
      });
    }
  };

  const isSent = !isSending && (hasSendError || hasSendSuccess);
  if (!form) return null;
  return (
    <FormModal
      isOpen={isOpen}
      onClose={() => handleClose()}
      hasSendError={hasSendError}
      hasSendSuccess={hasSendSuccess}
      form={form}
      labels={formLabels}
    >
      <FormProvider {...formMethods}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => formMethods.handleSubmit(onSubmit, onError)(e)}
          onChange={() => {
            if (hasSendError) setHasSendError(false);
          }}
        >
          <Grid container spacing={2}>
            <FormInputsLayout formItems={form.formItems} />

            {!isSent && (
              <Grid
                container
                item
                className={formItem}
                justifyContent="flex-end"
              >
                <SendButton isSending={isSending}>
                  {formLabels.submit}
                </SendButton>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </FormModal>
  );
};
