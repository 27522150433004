import { FC, useEffect, useState } from "react";

import { useRouter } from "next/router";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Grid } from "@busbud/design-system-components";

import { FormModal } from "@components/FormModal";
import { FormProps } from "@components/forms/formController";
import {
  FormInputsLayout,
  InputDefaultValue,
} from "@components/forms/formInputsLayout";
import { SendButton } from "@components/forms/inputs/sendButton";
import { useStyles } from "@components/forms/inputs/styles";
import { tracking } from "@services/tracking";

export const CancelTicket: FC<FormProps> = ({
  handleClose,
  isOpen,
  form,
  formLabels,
}) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [hasSendSuccess, setHasSendSuccess] = useState<boolean>(false);
  const [hasSendError, setHasSendError] = useState<boolean>(false);
  const { locale = "", push } = useRouter();

  useEffect(() => {
    if (isOpen) {
      tracking.openModal(form.formComponent, locale);
    }
  }, [isOpen, form, locale]);

  const { formItem } = useStyles();

  const defaultValues = form.formItems?.reduce((acc, item) => {
    if (!item) return acc;
    return {
      ...acc,
      [item.name]: InputDefaultValue[item.inputComponent],
    };
  }, {});

  const formMethods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const onError = () => {
    tracking.formClickSubmit(form.formComponent, false, locale || "");
  };

  const onSubmit: SubmitHandler<Record<string, string>> = async (
    data,
    event
  ) => {
    tracking.formClickSubmit(form.formComponent, true, locale);
    event?.preventDefault();

    setIsSending(true);
    if (
      window.grecaptcha &&
      process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_CLIENT_KEY
    ) {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(
            process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_CLIENT_KEY as string,
            {
              action: "submit/form/cancel",
            }
          );

          const req = {
            credit_card: "",
            departure_time: "",
            description: "",
            gdpr_request_type: "",
            name: "",
            purchase_id: "",
            review_content: "",
            route: "",
            request_type: "cancelation",
            credit_card_last4: data.cc,
            departure_date: data.departureDate,
            email: data.email,
            _captcha: token,
          };

          const res = await fetch("/hc/api/cancel", {
            method: "POST",
            body: JSON.stringify(req),
          });

          if (!res.ok) {
            tracking.formSubmitError(form.formComponent, locale);
            setIsSending(false);
            setHasSendError(true);
          } else {
            tracking.formSubmitSuccess(form.formComponent, locale);
            const resData = await res.json();
            if (resData.url) {
              tracking.cancelFormRedirect(locale);
              push(resData.url);
            }
            setHasSendSuccess(true);
            setIsSending(false);
          }
        } catch (error) {
          tracking.formSubmitError(form.formComponent, locale);
          setIsSending(false);
          setHasSendError(true);
        }
      });
    }
  };

  const isSent = !isSending && (hasSendError || hasSendSuccess);
  if (!form) return null;
  return (
    <FormModal
      labels={formLabels}
      form={form}
      isOpen={isOpen}
      onClose={() => handleClose()}
      hasSendError={hasSendError}
      hasSendSuccess={hasSendSuccess}
    >
      <FormProvider {...formMethods}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => formMethods.handleSubmit(onSubmit, onError)(e)}
        >
          <Grid container spacing={2}>
            <FormInputsLayout formItems={form.formItems} />
            {!isSent && (
              <Grid
                container
                item
                className={formItem}
                justifyContent="flex-end"
              >
                <SendButton isSending={isSending}>
                  {formLabels.submit}
                </SendButton>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </FormModal>
  );
};
