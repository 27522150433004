import { track } from "@amplitude/analytics-browser";

enum events {
  // forms
  selectedHelpCategory = "selected_help_category",
  clickedSubmitButtonHelpform = "clicked_submit_button_helpform",
  openedHelpformModal = "opened_helpform_modal",
  closedHelpformModal = "closed_helpform_modal",
  helpformSubmitSuccess = "helpform_submit_success",
  helpformSubmitError = "helpform_submit_error",
  clickedExpandHelpformModal = "clicked_expand_helpform_modal",
  redirectToAccountPage = "redirect_to_account_page",

  // navigation
  homePageView = "help_pageview",
  categoryPageView = "help_category_pageview",
  sectionPageView = "help_section_pageview",
  articlePageView = "help_article_pageview",
  notFound = "page_not_found",
  chesterPageView = "chester_pageview",
}

const sendTracking = (
  eventName: events,
  data: Record<string, any>,
  locale?: string
): void => {
  track(eventName, {
    ...data,
    locale,
    app: "web",
    site_section: "help_center",
  });
};

export const tracking = {
  // forms
  clickHelpButton: (
    helpCategory: Enum_Hcform_Formcomponent,
    locale: string
  ): void => {
    sendTracking(
      events.selectedHelpCategory,
      { help_category: helpCategory },
      locale
    );
  },
  chester: (locale: string): void => {
    sendTracking(events.chesterPageView, {}, locale);
  },
  openModal: (
    helpCategory: Enum_Hcform_Formcomponent,
    locale: string
  ): void => {
    sendTracking(
      events.openedHelpformModal,
      { help_category: helpCategory },
      locale
    );
  },
  closeModal: (
    helpCategory: Enum_Hcform_Formcomponent,
    locale: string
  ): void => {
    sendTracking(
      events.closedHelpformModal,
      { help_category: helpCategory },
      locale
    );
  },
  formClickSubmit: (
    helpCategory: Enum_Hcform_Formcomponent,
    isValidForm: boolean,
    locale: string
  ): void => {
    sendTracking(
      events.clickedSubmitButtonHelpform,
      { help_category: helpCategory, isValidForm },
      locale
    );
  },
  formSubmitSuccess: (
    helpCategory: Enum_Hcform_Formcomponent,
    locale: string
  ): void => {
    sendTracking(
      events.helpformSubmitSuccess,
      { help_category: helpCategory },
      locale
    );
  },
  formSubmitError: (
    helpCategory: Enum_Hcform_Formcomponent,
    locale: string
  ): void => {
    sendTracking(
      events.helpformSubmitError,
      { help_category: helpCategory },
      locale
    );
  },
  cancelFormRedirect: (locale: string): void => {
    sendTracking(events.redirectToAccountPage, {}, locale);
  },
  clickExpandForm: (
    helpCategory: Enum_Hcform_Formcomponent,
    locale: string
  ): void => {
    sendTracking(
      events.clickedExpandHelpformModal,
      { help_category: helpCategory },
      locale
    );
  },

  // navigation
  homePage: (locale: string): void => {
    sendTracking(events.homePageView, {}, locale);
  },
  categoryPage: (
    categoryId: number,
    catergoryName: string,
    locale: string
  ): void => {
    sendTracking(
      events.categoryPageView,
      { categoryId, catergoryName },
      locale
    );
  },
  sectionPage: (
    sectionId: number,
    sectionName: string,
    locale: string
  ): void => {
    sendTracking(events.sectionPageView, { sectionId, sectionName }, locale);
  },
  articlePage: (
    articleId: number,
    articleName: string,
    locale: string
  ): void => {
    sendTracking(events.articlePageView, { articleId, articleName }, locale);
  },
  notFound: (url: string, locale: string): void => {
    sendTracking(events.notFound, { url }, locale);
  },
};
