export const columns = (
  length: number,
  unit: "%" | "vw" = "%",
  base = 24
): string => {
  return `calc(${length} / ${base} * 100${unit})`;
};

export const negativeMargin = (parentPercentMargin: number): string => {
  return `calc((100 / (100 - ${parentPercentMargin} * 2)) * -${parentPercentMargin}%)`;
};
