import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { useRouter } from "next/router";

import Button from "@busbud/design-system-components/dist/Button";
import { GlobeMD } from "@busbud/design-system-components/dist/Icons";
import Label from "@busbud/design-system-components/dist/Label";
import Stack from "@busbud/design-system-components/dist/Stack";

import { LocalePickerDialog } from "@components/locale-picker-dialog";
import { LocaleKey, localeLabel } from "constants/i18n";

interface LocalePickerContextInterface {
  toggleDialog?: () => void;
  isDialogVisible: boolean;
}

export const LocalePickerContext = createContext<LocalePickerContextInterface>({
  isDialogVisible: false,
});

type Props = {
  onLocaleChange?: () => void;
  translations: LocalePickerTranslationsFragment;
};

type LocalePicker = FC<PropsWithChildren<Props>> & {
  Button: LocalePickerButton;
  MenuItem: LocalePickerMenuItem;
};

export const LocalePicker: LocalePicker = ({
  onLocaleChange,
  children,
  translations,
}) => {
  const router = useRouter();
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const toggleDialog = useCallback(
    () => setIsDialogVisible((menuState) => !menuState),
    []
  );

  const onLocaleSelected = useCallback(
    (locale: string) => {
      const { pathname, asPath, query } = router;
      router.replace({ pathname, query }, asPath, {
        locale,
      });
      setIsDialogVisible(false);
      onLocaleChange?.();
    },
    [router, onLocaleChange]
  );

  const value = useMemo(() => ({ toggleDialog, isDialogVisible }), [
    toggleDialog,
    isDialogVisible,
  ]);

  return (
    <LocalePickerContext.Provider value={value}>
      {children}
      <LocalePickerDialog
        onLocaleSelected={onLocaleSelected}
        translations={translations}
      />
    </LocalePickerContext.Provider>
  );
};

type LocalePickerButton = FC<React.ComponentProps<typeof Button>>;

const LocalePickerButton: LocalePickerButton = (props) => {
  const { toggleDialog } = useContext(LocalePickerContext);
  const { locale } = useRouter();

  return (
    <Button
      data-cy="open-picker-btn"
      color="tertiary"
      size="md"
      onClick={toggleDialog}
      {...props}
    >
      {localeLabel[`${locale}` as LocaleKey]}
    </Button>
  );
};

type LocalePickerMenuItem = FC<{ className?: string }>;

const LocalePickerMenuItem: LocalePickerMenuItem = ({ className }) => {
  const { toggleDialog } = useContext(LocalePickerContext);
  const { locale } = useRouter();

  return (
    <Stack alignItems="center" onClick={toggleDialog} className={className}>
      <Label
        size="lg"
        fontWeight="bold"
        startIcon={<GlobeMD color="icon.primary.opaque" />}
      >
        {localeLabel[`${locale}` as LocaleKey]}
      </Label>
      <Label size="lg">&nbsp;— {locale}</Label>
    </Stack>
  );
};

LocalePicker.Button = LocalePickerButton;
LocalePicker.MenuItem = LocalePickerMenuItem;
