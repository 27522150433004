import { FC } from "react";

import template from "lodash/template";
import { useRouter } from "next/router";

import Divider from "@busbud/design-system-components/dist/Divider";
import Label from "@busbud/design-system-components/dist/Label";
import Link from "@busbud/design-system-components/dist/Link";
import Stack from "@busbud/design-system-components/dist/Stack";
import makeStyles from "@busbud/design-system-components/dist/styles/makeStyles";

import { AppStoreBadge } from "@components/app-store-badge";
import { FooterCategories } from "@components/footer-categories";
import { FooterNavigation } from "@components/footer-navigation";
import { Section, useSectionNegativeMargins } from "@components/section";
import { ZendeskCategory } from "@services/zendesk/zendeskTypes";
import { urlBuilder } from "@utils/urlBuilder";

const useStyles = makeStyles(
  ({ busbud: { breakpoints, colors, styles, spacing } }) => ({
    root: {
      "& a": {
        color: "inherit",
      },
    },
    legal: {
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
    },
    legalLeftPart: {
      flex: 2,
      [breakpoints.up("lg")]: {
        display: "flex",
        "& > *": {
          flex: 1,
        },
      },
    },
    dotDivider: {
      display: "none",
      width: spacing(0.5),
      height: spacing(0.5),
      borderRadius: styles.rounding.circle,
      backgroundColor: colors.border.secondary.opaque,
      [breakpoints.up("sm")]: {
        display: "block",
      },
    },
  })
);

interface Props {
  categories?: ZendeskCategory[];
  withSearch?: boolean;
  translations: FooterTranslationsFragment &
    FooterCategoriesTranslationsFragment;
}

export const Footer: FC<Props> = ({ categories, translations, withSearch }) => {
  const classes = useStyles();
  const negativeMarginClasses = useSectionNegativeMargins();
  const { locale = "" } = useRouter();

  return (
    <footer>
      {!!categories?.length && (
        <FooterCategories
          categories={categories}
          translations={translations}
          withSearch={withSearch}
        />
      )}
      <Section
        className={classes.root}
        color="background.primary.opaque"
        curveDirection="reverse"
        noPadding
      >
        <FooterNavigation translations={translations} />
        <Divider className={negativeMarginClasses.root} />
        <Stack
          className={classes.legal}
          spacing={{ xs: 3, sm: 0 }}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
        >
          <Stack
            className={classes.legalLeftPart}
            spacing={{ xs: 2, sm: 0 }}
            direction={{ xs: "column", lg: "row" }}
          >
            <Label
              size="sm"
              fontWeight="bold"
              color="text.tertiary.opaque"
              component="p"
            >
              {template(translations.legalCopyright ?? "")({
                currentYear: new Date().getFullYear(),
              })}
            </Label>
            <Stack
              spacing={1}
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              justifyContent={{ xs: "flex-start", lg: "center" }}
            >
              <Link
                href={urlBuilder.termsOfUse(locale)}
                size={{ xs: "md", sm: "sm" }}
                fontWeight="bold"
                align="center"
                noWrap
              >
                {translations.legalNavItemTerms}
              </Link>
              <div className={classes.dotDivider} />
              <Link
                href={urlBuilder.privacyPolicy(locale)}
                size={{ xs: "md", sm: "sm" }}
                fontWeight="bold"
                align="center"
                noWrap
              >
                {translations.legalNavItemPrivacy}
              </Link>
              <div className={classes.dotDivider} />
              <Link
                href={urlBuilder.refundPolicy(locale)}
                size={{ xs: "md", sm: "sm" }}
                fontWeight="bold"
                align="center"
                noWrap
              >
                {translations.legalNavItemRefund}
              </Link>
            </Stack>
          </Stack>
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
            flex="1"
          >
            <Link href={urlBuilder.iosApp(locale)} target="_blank">
              <AppStoreBadge
                platform="ios"
                alt={translations.badgeAppStore ?? ""}
              />
            </Link>
            <Link href={urlBuilder.androidApp(locale)} target="_blank">
              <AppStoreBadge
                platform="android"
                alt={translations.badgePlayStore ?? ""}
              />
            </Link>
          </Stack>
        </Stack>
      </Section>
    </footer>
  );
};
