import { FC, useContext } from "react";

import clsx from "classnames";
import { useRouter } from "next/router";

import Button from "@busbud/design-system-components/dist/Button";
import Dialog from "@busbud/design-system-components/dist/Dialog";
import Divider from "@busbud/design-system-components/dist/Divider";
import IconButton from "@busbud/design-system-components/dist/IconButton";
import { CrossMD } from "@busbud/design-system-components/dist/Icons";
import Label from "@busbud/design-system-components/dist/Label";
import Stack from "@busbud/design-system-components/dist/Stack";
import makeStyles from "@busbud/design-system-components/dist/styles/makeStyles";

import { LocalePickerContext } from "@components/locale-picker";
import { LocaleKey, localeLabel } from "constants/i18n";

const useStyles = makeStyles(({ busbud: { colors, spacing, styles } }) => ({
  container: {
    width: spacing(50),
    maxWidth: "95%",
    marginTop: spacing(10),
    borderRadius: styles.rounding.lg,
  },
  scrollBox: {
    alignItems: "flex-start",
  },
  item: {
    minWidth: spacing(20),
    justifyContent: "flex-start",
  },
  active: {
    border: `${styles.borderWidth.sm} solid ${colors.inverseBorder.tertiary.opaque}`,
  },
}));

type LocalePickerDialogProps = {
  onLocaleSelected: (locale: string) => void;
  translations: LocalePickerTranslationsFragment;
};

export const LocalePickerDialog: FC<LocalePickerDialogProps> = ({
  onLocaleSelected,
  translations,
}) => {
  const { locales, locale: currentLocale } = useRouter();
  const classes = useStyles();
  const { isDialogVisible, toggleDialog } = useContext(LocalePickerContext);

  return (
    <Dialog
      classes={{
        box: classes.container,
        scrollBox: classes.scrollBox,
      }}
      open={isDialogVisible}
      onClose={toggleDialog}
      aria-labelledby="closeable-dialog-title"
      aria-describedby="closeable-dialog-description"
    >
      <Stack justifyContent="space-between" alignItems="center" p={1.5}>
        <Label size="lg" fontWeight="bold">
          {translations?.language}
        </Label>
        <IconButton
          onClick={toggleDialog}
          size="sm"
          variant="text"
          color="tertiary"
        >
          <CrossMD />
        </IconButton>
      </Stack>
      <Divider />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        flexWrap="wrap"
        spacing={1}
        p={2}
        alignItems="flex-start"
      >
        {(locales ?? []).map((locale) => (
          <Button
            data-cy="locale-btn"
            key={locale}
            onClick={() => onLocaleSelected(locale)}
            size="md"
            variant="text"
            color="tertiary"
            className={clsx(classes.item, {
              [classes.active]: currentLocale === locale,
            })}
          >
            {localeLabel[`${locale}` as LocaleKey]}
          </Button>
        ))}
      </Stack>
    </Dialog>
  );
};
