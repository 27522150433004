import { FC, PropsWithChildren, useState } from "react";

import { useRouter } from "next/router";

import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
} from "@busbud/design-system-components";
import {
  CaretDownMD,
  CaretUpMD,
} from "@busbud/design-system-components/dist/Icons";
import { makeStyles } from "@busbud/design-system-components/dist/styles";

import { tracking } from "@services/tracking";

const useStyles = makeStyles(() => {
  return {
    collapseHeader: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    card: {
      overflow: "visible",
    },
  };
});

interface Props {
  cta: string;
  formKey: Enum_Hcform_Formcomponent;
}

export const FormCollapse: FC<PropsWithChildren<Props>> = ({
  cta,
  children,
  formKey,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { collapseHeader, card } = useStyles();
  const { locale = "" } = useRouter();
  return (
    <Card boxShadow="unset" className={card}>
      <CardHeader
        title={cta}
        onClick={() => {
          tracking.clickExpandForm(formKey, locale);
          setIsExpanded(!isExpanded);
        }}
        className={collapseHeader}
      >
        {isExpanded ? <CaretUpMD /> : <CaretDownMD />}
      </CardHeader>
      <Collapse in={isExpanded} timeout="auto">
        <Divider />
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};
