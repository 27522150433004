import { FC } from "react";

import classNames from "classnames";
import dayjs from "dayjs";

import { Grid } from "@busbud/design-system-components";

import { DateInput } from "@components/forms/inputs/date";
import { EmailInput } from "@components/forms/inputs/email";
import { Select } from "@components/forms/inputs/select";
import { useStyles } from "@components/forms/inputs/styles";
import { TextField } from "@components/forms/inputs/text";
import { TextArea } from "@components/forms/inputs/textArea";

interface Props {
  formItems: FormsFragment["formItems"];
}

type Item = NonNullable<NonNullable<FormsFragment["formItems"]>[number]>;

export interface FormInputProps {
  formItem: Item;
}

const InputComponents: Record<
  Enum_Componentformsformitem_Inputcomponent,
  FC<FormInputProps>
> = {
  select: Select,
  email: EmailInput,
  text: TextField,
  textArea: TextArea,
  date: DateInput,
};

export const InputDefaultValue: Record<
  Enum_Componentformsformitem_Inputcomponent,
  string | null
> = {
  select: "",
  email: "",
  text: "",
  textArea: "",
  date: dayjs().format("YYYY-MM-DD"),
};

export const FormInputsLayout: FC<Props> = ({ formItems }) => {
  const { formItem, fullWidth } = useStyles();

  return (
    <>
      {formItems?.map((item) => {
        if (!item) return null;
        const Input = InputComponents[item.inputComponent];
        return (
          <Grid
            key={item.id}
            item
            className={classNames({
              [formItem]: true,
              [fullWidth]: item.isFullWidth,
            })}
          >
            <Input formItem={item} />
          </Grid>
        );
      })}
    </>
  );
};
