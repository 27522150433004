import { makeStyles } from "@busbud/design-system-components/dist/styles";

export const useStyles = makeStyles(({ spacing }) => {
  return {
    dialogText: {
      marginBottom: spacing(3),
    },
    formItem: {
      flexGrow: 1,
    },
    fullWidth: {
      width: "100%",
    },
  };
});
