import { FC } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@busbud/design-system-components";
import Alert from "@busbud/design-system-components/dist/Alert";
import AlertTitle from "@busbud/design-system-components/dist/AlertTitle";
import { ExternalLinkMD } from "@busbud/design-system-components/dist/Icons";
import Stack from "@busbud/design-system-components/dist/Stack";
import { makeStyles } from "@busbud/design-system-components/dist/styles";
import Text from "@busbud/design-system-components/dist/Text";

import { FormCollapse } from "@components/forms/formCard";
import { ZendeskHtmlParser } from "@components/zendeskHtmlParser";

interface Props {
  children: React.ReactElement;
  hasSendError: boolean;
  hasSendSuccess: boolean;
  isOpen: boolean;
  onClose: () => void;
  form: FormsFragment;
  labels: HcSharedFormLabel;
}

const useStyles = makeStyles(({ spacing }) => {
  return {
    body: {
      marginBottom: spacing(3),
    },
    scroll: {
      overflow: "visible",
    },
  };
});

export const FormModal: FC<Props> = ({
  children,
  hasSendError,
  hasSendSuccess,
  isOpen,
  onClose,
  form,
  labels,
}) => {
  const { body, scroll } = useStyles();
  const bodyElement = ZendeskHtmlParser({ body: form.body });
  const errorMessage = ZendeskHtmlParser({ body: form.errorMessage.message });
  return (
    <Dialog
      scroll="body"
      onClose={onClose}
      open={isOpen}
      BoxProps={{ className: scroll }}
      disableBackdropClick
    >
      <DialogTitle onClose={() => onClose()}>{form.title}</DialogTitle>
      <Divider />
      <DialogContent className={scroll}>
        <Stack direction={"column"} spacing={2.5}>
          {form.modalAlerts?.map((alert) => {
            if (!alert) return null;
            return (
              <Alert key={alert.id} severity={alert.severity}>
                {!!alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                {alert.message}
              </Alert>
            );
          })}
          <Text className={body} component="div">
            {bodyElement}
          </Text>
          {form.expandCta ? (
            <FormCollapse formKey={form.formComponent} cta={form.expandCta}>
              {children}
            </FormCollapse>
          ) : (
            children
          )}
          {hasSendSuccess && (
            <Alert severity={form.successMessage.severity}>
              {form.successMessage?.title && (
                <AlertTitle>{form.successMessage?.title}</AlertTitle>
              )}
              {form.successMessage.message}
            </Alert>
          )}
          {hasSendError && (
            <Alert severity={form.errorMessage.severity}>{errorMessage}</Alert>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button size={"md"} color="tertiary" onClick={() => onClose()}>
          {labels.cancel}
        </Button>
        <Button
          endIcon={<ExternalLinkMD />}
          color="primary"
          href="https://www.busbud.com/en/account/signin"
        >
          {labels.login}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
