import dayjs from "dayjs";
import localData from "dayjs/plugin/localeData";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

import {
  CalendarContextState,
  IsoDate,
} from "@busbud/design-system-components/dist/Calendar/CalendarContext";

import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/pt-br";
import "dayjs/locale/ru";
import "dayjs/locale/tr";
import "dayjs/locale/fr";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localData);
dayjs.extend(updateLocale);

type Translations = Record<"nextMonth" | "previousMonth" | "selected", string>;

export class DateAdapter implements CalendarContextState {
  constructor(
    public translations: Translations,
    private locale: string = "en"
  ) {
    this.translations = { ...translations };
    this.getToday = this.getToday.bind(this);
    this.getWeekdays = this.getWeekdays.bind(this);
    this.getMonthYear = this.getMonthYear.bind(this);
    this.getWeekArray = this.getWeekArray.bind(this);
    this.formatForDisplay = this.formatForDisplay.bind(this);

    dayjs.updateLocale(locale, {
      weekStart: 0,
    });
  }

  getToday(): IsoDate {
    return dayjs().locale(this.locale).format("YYYY-MM-DD");
  }

  addDays(date: IsoDate, nbDaysToAdd: number): IsoDate {
    return dayjs(date).add(nbDaysToAdd, "day").format("YYYY-MM-DD");
  }

  getWeekdays(): IsoDate[] {
    return dayjs().locale(this.locale).localeData().weekdaysMin();
  }

  getMonthYear(date: IsoDate): IsoDate {
    return dayjs(date).locale(this.locale).format("MMMM YYYY");
  }

  getWeekArray(date: IsoDate): IsoDate[][] {
    const dayjsDate = dayjs(date).locale(this.locale);
    const start = dayjsDate.startOf("month").startOf("week");
    const end = dayjsDate.endOf("month").endOf("week");

    let count = 0;
    let current = start;
    const nestedWeeks: IsoDate[][] = [];

    while (current.isBefore(end)) {
      const weekNumber = Math.floor(count / 7);

      nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
      nestedWeeks[weekNumber].push(current.format("YYYY-MM-DD"));

      current = current.add(1, "day");
      count += 1;
    }

    return nestedWeeks;
  }

  formatForDisplay(date: string | undefined): string {
    if (!date) return "";

    return dayjs(date).locale(this.locale).format("D MMMM ddd");
  }
}
