import React, { useEffect, useState } from "react";

import Image from "next/image";
import { useRouter } from "next/router";

interface Props {
  platform: "ios" | "android";
  alt: string;
}

const imageProps = {
  ios: (locale: string) => ({
    src: `/app-store/ios/app-store-badge-${locale}.png`,
    defaultImage: "/app-store/android/google-play-badge-en.png",
  }),
  android: (locale: string) => ({
    src: `/app-store/android/google-play-badge-${locale}.png`,
    defaultImage: "/app-store/android/google-play-badge-en.png",
  }),
};

export const AppStoreBadge: React.FC<Props> = ({ platform, alt }) => {
  const { locale = "" } = useRouter();
  const { src, defaultImage } = imageProps[platform](locale.substring(0, 2));
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    const { src } = imageProps[platform](locale.substring(0, 2));
    setImgSrc(src);
  }, [locale, platform]);

  return (
    <Image
      src={imgSrc}
      alt={alt}
      height={32}
      width="100%"
      layout="fixed"
      objectFit="contain"
      onError={() => {
        setImgSrc(defaultImage);
      }}
    />
  );
};
