import clsx from "classnames";
import get from "lodash/fp/get";

import { layoutConstants } from "@busbud/design-system-components/dist/Layout";
import LayoutSection from "@busbud/design-system-components/dist/Layout/LayoutSection";
import makeStyles from "@busbud/design-system-components/dist/styles/makeStyles";
import { ColorKeys } from "@busbud/design-system-components/dist/themes/busbud/tokens";

import { negativeMargin } from "@utils/style";

import { SVGCurve } from "./svg-curve";

type ClassKeys = "root" | "curve" | "rootPadding";

export const CURVE_HEIGHT = "2.777777778vw";

const useStyles = makeStyles<ClassKeys, Pick<SectionProps, "color">>(
  ({ busbud: { colors, spacing, breakpoints } }) => ({
    root: ({ color }) => ({
      position: "relative",
      backgroundColor: get(color, colors),
    }),
    rootPadding: {
      paddingTop: spacing(3),
      paddingBottom: `calc(${spacing(5)} + ${CURVE_HEIGHT})`,
      [breakpoints.up("sm")]: {
        paddingTop: spacing(5),
        paddingBottom: `calc(${spacing(10)} + ${CURVE_HEIGHT})`,
      },
    },
    curve: ({ color }) => ({
      color: get(color, colors),
      position: "absolute",
      height: CURVE_HEIGHT,
      top: `calc(-${CURVE_HEIGHT} + 0.03125rem)`,
      left: 0,
      right: 0,
      "& svg": {
        display: "block",
        width: "100%",
        height: "100%",
      },
    }),
  })
);

type SectionProps = React.PropsWithChildren<{
  className?: string;
  color: ColorKeys;
  curveDirection?: "normal" | "reverse";
  id?: string;
  noPadding?: boolean;
  gutters?: number;
}>;

export const Section: React.FC<SectionProps> = ({
  children,
  className,
  color,
  curveDirection = "normal",
  id,
  noPadding,
  gutters,
}) => {
  const classes = useStyles({ color });
  return (
    <LayoutSection
      id={id}
      className={clsx(
        className,
        classes.root,
        !noPadding && classes.rootPadding
      )}
      gutters={gutters}
    >
      <div
        className={classes.curve}
        style={
          curveDirection === "reverse" ? { transform: "scaleX(-1)" } : undefined
        }
      >
        <SVGCurve />
      </div>
      {children}
    </LayoutSection>
  );
};

export const useSectionNegativeMargins = makeStyles(
  ({ busbud: { breakpoints, spacing } }) => ({
    root: {
      marginRight: spacing(-layoutConstants.outerSpacing.upXs),
      marginLeft: spacing(-layoutConstants.outerSpacing.upXs),

      [breakpoints.up(360)]: {
        marginRight: spacing(-layoutConstants.outerSpacing.up360),
        marginLeft: spacing(-layoutConstants.outerSpacing.up360),
      },

      [breakpoints.up(480)]: {
        marginRight: negativeMargin(
          parseFloat(layoutConstants.outerSpacing.up480)
        ),
        marginLeft: negativeMargin(
          parseFloat(layoutConstants.outerSpacing.up480)
        ),
      },

      [breakpoints.up("md")]: {
        marginRight: negativeMargin(
          parseFloat(layoutConstants.outerSpacing.upMd)
        ),
        marginLeft: negativeMargin(
          parseFloat(layoutConstants.outerSpacing.upMd)
        ),
      },
    },
  })
);

export const useMainSectionPadding = makeStyles(({ busbud: { spacing } }) => ({
  main: {
    padding: spacing(8, 0, 16, 0),
  },
}));
