import React, { FC, PropsWithChildren } from "react";

import { Button } from "@busbud/design-system-components";
import CircularProgress from "@busbud/design-system-components/dist/CircularProgress";
import { LongArrowRightLG } from "@busbud/design-system-components/dist/Icons";

interface Props {
  isSending: boolean;
}

export const SendButton: FC<PropsWithChildren<Props>> = ({
  isSending,
  children,
}) => {
  return (
    <Button
      type="submit"
      size={"md"}
      color="secondary"
      disabled={isSending}
      endIcon={!isSending && <LongArrowRightLG />}
    >
      {isSending ? <CircularProgress /> : children}
    </Button>
  );
};
