import { isArray } from "lodash";

import gdprRequest from "./gdpr_request";
import reviewReport from "./review_report";

const request_types: Record<
  string,
  (
    requestType: Enum_Hcform_Requesttype,
    formFields: Record<string, string>
  ) => string[]
> = {
  gdpr_request: gdprRequest,
  review_report: reviewReport,
};

export const buildRequestTypeTags = (
  requestType: Enum_Hcform_Requesttype,
  formFields: Record<string, string>
): string[] => {
  const requestTypeFunction = request_types[requestType];
  if (requestTypeFunction) {
    const tags = requestTypeFunction(requestType, formFields);
    if (!isArray(tags)) {
      // eslint-disable-next-line no-console
      console.error("tags should be an array");
      return [`helpform/${requestType}`];
    }
    return tags;
  }
  return [`helpform/${requestType}`];
};
