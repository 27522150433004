import { buildRequestTypeTags } from "./forms";

const REQUEST_TYPE_FIELD_ID = "25302546";

interface ZendeskTicketFields {
  requester: {
    name: string;
    email: string;
  };
  subject: string;
  comment: { body: string };
  tags: string[];
  custom_Fields: { id: string; value: string }[];
}

export interface TicketMessageArgs {
  requestType: Enum_Hcform_Requesttype;
  name?: string;
  email: string;
  comment?: string;
  formFields: Record<string, any>;
  subject: string;
}

export const createZendeskTicket = async (
  ticket: ZendeskTicketFields,
  recaptchaToken: string
): Promise<Response> => {
  return await fetch("/hc/api/ticket", {
    method: "POST",
    body: JSON.stringify({ ticket, recaptchaToken }),
  });
};

export const buildTicketMessage = ({
  requestType,
  name = "N/A",
  email = "N/A",
  comment = "No comment from the user",
  formFields,
  subject,
}: TicketMessageArgs): ZendeskTicketFields => {
  const additionalInfo = `
${comment}
    
—---------------------------------------------------------------
Additional information provided by the customer on the helpform:
---------------------------------------------------------------- 

${Object.keys(formFields)
  .filter((val) => val !== "comment")
  .map((item) => {
    return `
  ${item}: ${formFields[item]}`;
  })}
                

`;

  const tags = ["submitted_by_user", "helpform"];
  const additionnalTags = buildRequestTypeTags(requestType, formFields);

  return {
    comment: { body: additionalInfo },
    custom_Fields: [
      {
        id: REQUEST_TYPE_FIELD_ID,
        value: requestType,
      },
    ],
    requester: {
      email,
      name,
    },
    subject,
    tags: [...tags, ...additionnalTags],
  };
};
