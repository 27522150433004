import { FC } from "react";

import { useRouter } from "next/router";

import { CancelTicket } from "@components/forms/cancelTicket";
import { GenericZendeskForm } from "@components/forms/ZendeskGenericForm";
import { tracking } from "@services/tracking";

export interface FormProps {
  isOpen: boolean;
  handleClose: () => void;
  form: FormsFragment;
  formLabels: SharedLabelsFragment;
}

const FormMap: Record<Enum_Hcform_Formcomponent, FC<FormProps>> = {
  contact: GenericZendeskForm,
  exchange_tickets: GenericZendeskForm,
  find_boarding_information: GenericZendeskForm,
  trip_feedback: GenericZendeskForm,
  trip_not_available: GenericZendeskForm,
  personal_data: GenericZendeskForm,
  failed_booking: GenericZendeskForm,
  resend_confirmation: GenericZendeskForm,
  antt: GenericZendeskForm,
  cancel_tickets: CancelTicket,
};

const isValidFormQueryKey = (
  val: string | Enum_Hcform_Formcomponent
): val is Enum_Hcform_Formcomponent => {
  return Object.keys(FormMap).includes(val);
};

interface Props {
  forms: FormsFragment[];
  formLabels: SharedLabelsFragment;
}

export const FormController: FC<Props> = ({ forms, formLabels }) => {
  const router = useRouter();
  const currentForm = router.query.form;

  if (
    !currentForm ||
    typeof currentForm !== "string" ||
    !isValidFormQueryKey(currentForm)
  ) {
    return null;
  }

  const handleClose = () => {
    const {
      pathname,
      query: { form: _form, ...query },
    } = router;
    tracking.closeModal(currentForm, router.locale || "");
    router.replace({ pathname, query }, undefined, { shallow: true });
  };

  const formsIndex = forms.reduce(
    (prev: Record<string, FormsFragment>, curr) => {
      if (!curr.formComponent) return prev;
      return {
        ...prev,
        [curr.formComponent]: curr,
      };
    },
    {}
  );

  const FormComponent = FormMap[currentForm];
  return (
    <FormComponent
      formLabels={formLabels}
      form={formsIndex[`${currentForm}`]}
      isOpen={true}
      handleClose={() => handleClose()}
    />
  );
};
