import { ResponsiveColorProp } from "@busbud/design-system-components/dist/system/palette";

export const CATEGORIES: {
  [id: string]: {
    /** dots in bulletedList */
    solidColor: string;
    /** contrasting text on dark bg */
    textColor: ResponsiveColorProp;
    backgroundColorDark: string;
    backgroundColorLight: string;
    heroBackgroundColor: string;
    image: string;
  };
} = {
  /** book tickets */
  "115001267646": {
    solidColor: "#0271CA",
    backgroundColorDark: "linear-gradient(149.88deg, #0271CA 0%, #248AE3 100%)",
    textColor: "text.overBackground.opaque",
    backgroundColorLight: "#D6F5FF",
    heroBackgroundColor: "hsla(203, 100%, 59%, 0.1)",
    image: "cart.svg",
  },
  /** manage bookings */
  "115001240163": {
    solidColor: "#FF7E1C",
    backgroundColorDark: "linear-gradient(149.88deg, #FF7E1C 0%, #FF9C45 100%)",
    textColor: "text.overBackground.opaque",
    backgroundColorLight: "#FFE3BF",
    heroBackgroundColor: "hsla(36, 100%, 56%, 0.1)",
    image: "ticket.svg",
  },
  /** Travelling */
  "360003778191": {
    solidColor: "#FFBF00",
    backgroundColorDark: "linear-gradient(149.69deg, #FFBF00 0%, #FCCB29 100%)",
    textColor: "text.special.opaque",
    backgroundColorLight: "#FFF0B5",
    heroBackgroundColor: "hsla(45, 100%, 50%, 0.1)",
    image: "suitcase.svg",
  },
  /** operator policies */
  "115001234426": {
    solidColor: "#24D474",
    backgroundColorDark: "linear-gradient(149.88deg, #24D474 0%, #43E084 100%)",
    textColor: "text.overBackground.opaque",
    backgroundColorLight: "#CFFCDD",
    heroBackgroundColor: "hsla(149, 94%, 50%, 0.1)",
    image: "ask-help.svg",
  },
  /** covid */
  "360002728812": {
    solidColor: "#20415A",
    backgroundColorDark: "linear-gradient(150deg, #20415A 0%, #436783 100%)",
    textColor: "text.overBackground.opaque",
    backgroundColorLight: "#F2F6F8",
    heroBackgroundColor: "hsla(205, 29%, 66%, 0.1)",
    image: "virus.svg",
  },
};
