import { FC, FormEventHandler, useState } from "react";

import { useRouter } from "next/router";

import { Button, TextField } from "@busbud/design-system-components";
import Hidden from "@busbud/design-system-components/dist/Hidden";
import IconButton from "@busbud/design-system-components/dist/IconButton";
import {
  SearchLG,
  SearchMD,
} from "@busbud/design-system-components/dist/Icons";
import { makeStyles } from "@busbud/design-system-components/dist/styles";

const useStyles = makeStyles(
  ({ busbud: { breakpoints, styles, spacing, colors } }) => ({
    form: {
      width: "100%",
      boxShadow: styles.shadow.subtle.md,
      borderRadius: styles.rounding.lg,
      backgroundColor: colors.background.primary.opaque,
      display: "flex",
      alignItems: "center",
      [breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    input: {
      "& input": {
        textOverflow: "ellipsis",
        paddingTop: `${spacing(1)} !important`,
      },
    },
    button: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
    textField: {
      padding: spacing(0.5),
      [breakpoints.up("sm")]: {
        display: "block",
      },
    },
  })
);

type Props = {
  translations: SearchInputTranslationsFragment;
};

export const SearchInput: FC<Props> = ({ translations }) => {
  const classes = useStyles();
  const router = useRouter();

  const queryFromURL = router.query.query as string | undefined;

  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    queryFromURL || undefined
  );

  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    router.push({
      pathname: "/search",
      query: { query: searchQuery, page: 1 },
    });
  };

  return (
    <form onSubmit={handleSearchSubmit} className={classes.form}>
      <TextField
        variant="floating"
        className={classes.textField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchQuery(e.target.value)
        }
        value={searchQuery}
        placeholder={translations.searchPlaceholder}
        InputProps={{
          className: classes.input,
          endAdornment: (
            <Hidden smUp>
              <IconButton size="md" type="submit">
                <SearchMD />
              </IconButton>
            </Hidden>
          ),
        }}
        expanded
        fullWidth
      />
      <Hidden mdUp xsDown>
        <IconButton
          size="xl"
          type="submit"
          variant="contained"
          className={classes.button}
        >
          <SearchLG />
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <Button
          size="xl"
          startIcon={<SearchLG />}
          type="submit"
          variant="contained"
          className={classes.button}
        >
          {translations.searchButton}
        </Button>
      </Hidden>
    </form>
  );
};
