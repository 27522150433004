import { FC } from "react";

import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useController, useFormContext } from "react-hook-form";

import FormControl from "@busbud/design-system-components/dist/FormControl";
import { CalendarInput } from "@busbud/forms/dist/UI/components/calendar-input";

import { FormInputProps } from "@components/forms/formInputsLayout";
import { DateAdapter } from "@components/forms/inputs/date-adapter";
import { LocaleKey, localesToDayjsLocale } from "constants/i18n";

export const DateInput: FC<FormInputProps> = ({
  formItem: { name, label, placeholder, isRequired = false },
}) => {
  const { control, setValue, getValues } = useFormContext();
  const { locale, defaultLocale } = useRouter();
  const {
    field: { ref, ...field },
    fieldState,
    formState,
  } = useController({
    control,
    name,
    rules: {
      required: isRequired,
    },
  });
  const value = getValues(name);
  const hasValue = Boolean(value);
  const adapter = new DateAdapter(
    // TODO: move these to strapi. These are used for the aria labels on the buttons
    {
      nextMonth: "Next month",
      previousMonth: "Previous month",
      selected: "selected",
    },
    locale ? localesToDayjsLocale[locale as LocaleKey] : defaultLocale
  );
  const min_selectable_date = dayjs()
    .subtract(10, "month")
    .format("YYYY-MM-DD");
  return (
    <FormControl fullWidth>
      <CalendarInput
        label={label || ""}
        variant="filled"
        placeholder={placeholder || ""}
        error={Boolean(fieldState.error)}
        helperText={`${formState.errors[name]?.message || ""}`}
        inputRef={ref}
        expanded={false}
        size={"md"}
        InputLabelProps={{
          shrink: hasValue,
        }}
        {...field}
        onChange={(date) => setValue(name, date)}
        adapter={adapter}
        minSelectableDate={min_selectable_date}
        maxMonthsDisplay={12}
      />
    </FormControl>
  );
};
