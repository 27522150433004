import { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { FormControl, TextField } from "@busbud/design-system-components";

import { FormInputProps } from "@components/forms/formInputsLayout";

export const TextArea: FC<FormInputProps> = ({
  formItem: { name, isRequired, label, placeholder },
}) => {
  const { control, formState } = useFormContext();
  const errorMessage = formState.errors[name];

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { ref, ...field } }) => (
        <FormControl fullWidth>
          <TextField
            label={label}
            variant="filled"
            multiline={true}
            error={Boolean(errorMessage)}
            helperText={errorMessage?.message}
            inputRef={ref}
            placeholder={placeholder}
            {...field}
          />
        </FormControl>
      )}
    />
  );
};
