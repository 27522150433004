import { deburr } from "lodash";
import qs from "query-string";

import { LocaleKey, localesToPubwebLocale } from "constants/i18n";

export const pubwebBaseUrl = "https://www.busbud.com";
export const baseUrl = "https://help.busbud.com/hc";

const contactQs: Enum_Hcform_Formcomponent = "contact";

const convertToSlug = (text: string) => {
  return deburr(text)
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .trim()
    .replace(/ +/g, "-");
};

const addBaseUrl = (relativeUrl: string, absolute: boolean) => {
  return absolute ? `${baseUrl}${relativeUrl}` : relativeUrl;
};

const getPubwebLocale = (locale: string) =>
  localesToPubwebLocale[locale as LocaleKey];

const getBlogLocale = (locale: string) => {
  // "en" version of the blog is the root, and /en redirects to an article
  if (locale === "en-us") return "";
  return getPubwebLocale(locale);
};

export const urlBuilder = {
  // Main nav
  pubweb: (locale: string): string =>
    `${pubwebBaseUrl}/${getPubwebLocale(locale)}`,
  whoWeAre: (locale: string): string =>
    `${pubwebBaseUrl}/${getPubwebLocale(locale)}/about`,
  blog: (locale: string): string =>
    `${pubwebBaseUrl}/blog/${getBlogLocale(locale)}`,
  newsroom: (locale: string): string =>
    `${pubwebBaseUrl}/${getPubwebLocale(locale)}/about/press`,
  contact: (currentUrl: string): string =>
    `${qs.stringifyUrl({
      url: currentUrl,
      query: { form: contactQs },
    })}`,

  // Careers
  careers: (locale: string): string =>
    `${pubwebBaseUrl}/${getPubwebLocale(locale)}/careers`,

  // Legal
  privacyPolicy: (locale: string): string =>
    `${pubwebBaseUrl}/${getPubwebLocale(locale)}/about/privacy`,
  termsOfUse: (locale: string): string =>
    `${pubwebBaseUrl}/${getPubwebLocale(locale)}/about/tos`,
  refundPolicy: (locale: string): string =>
    `${pubwebBaseUrl}/${getPubwebLocale(locale)}/about/refunds`,

  // Apps
  iosApp: (locale: string): string =>
    `itms-apps://apps.apple.com/${getPubwebLocale(
      locale
    )}/app/busbud/id569615989`,
  androidApp: (locale: string): string =>
    `https://play.google.com/store/apps/details?id=com.busbud.android&hl=${getPubwebLocale(
      locale
    )}`,

  // Social
  facebook: "https://www.facebook.com/Busbud",
  instagram: "https://www.instagram.com/busbud/",
  twitter: "https://www.twitter.com/Busbud",

  // Internal
  article: (
    locale: string,
    id: number,
    name: string,
    absolute = false
  ): string =>
    addBaseUrl(`/${locale}/articles/${id}-${convertToSlug(name)}`, absolute),
  category: (
    locale: string,
    id: number,
    name: string,
    absolute = false
  ): string =>
    addBaseUrl(`/${locale}/categories/${id}-${convertToSlug(name)}`, absolute),
  section: (
    locale: string,
    id: number,
    name: string,
    absolute = false
  ): string =>
    addBaseUrl(`/${locale}/section/${id}-${convertToSlug(name)}`, absolute),
};
