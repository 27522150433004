import { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";

import FormControl from "@busbud/design-system-components/dist/FormControl";
import InputLabel from "@busbud/design-system-components/dist/InputLabel";
import MenuItem from "@busbud/design-system-components/dist/MenuItem";
import DSCLSelect from "@busbud/design-system-components/dist/Select";

import { FormInputProps } from "@components/forms/formInputsLayout";

export const Select: FC<FormInputProps> = ({
  formItem: { isRequired = false, name, label, options },
}) => {
  const { control, formState } = useFormContext();
  const errorMessage = formState.errors[name];

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { ref, ...field } }) => (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <DSCLSelect error={Boolean(errorMessage)} innerRef={ref} {...field}>
            {(options || []).map((option) => {
              if (!option) return null;
              return (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </DSCLSelect>
        </FormControl>
      )}
    />
  );
};
